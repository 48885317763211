@import '../../styles/base.scss';

:local {
  .accordion {
    max-width: 560px;
    margin: auto;
    margin-bottom: 23px;

    .clickable {
      min-height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $grey-10;
      cursor: pointer;
      padding-right: 36px;
      font-size: 16px;
    }

    .title {
      font-weight: $bold-text;
    }

    .panel {
      transition: $transition-slow;
      overflow: hidden;
    }
    
    .paragraph {
      padding-top: 30px;
      margin: 0 auto;
    }

    .plusMinusIcon {
      .bar {
        position: absolute;
        background-color: $grey-90;
        height: 2px;
        width: 20px;
        transition: $transition-fast;
        border-radius: 1px;
      }

      .verticalBar {
        transform: rotate(90deg);
      }
    }
  }
}