@import '../../styles/base.scss';

:local {
  .footer {
    position: absolute;
    left: 0;
    width: 100vw;

    .top {
      display: flex;
      justify-content: center;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

      .topWrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        
        @media screen and (min-width: $large-content-width) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 38px 10% 52px;
          width: calc(100vw - 20%);
        }
        
        .logos {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 40px;
          
          .logo {
            width: 223px;
          }
          
          @media screen and (min-width: $large-content-width) {
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 0;
            
            .logo {
              width: 186px;
            }
          }

          .logoSubtitle {
            font-size: 12px;
            line-height: 24px;
            color: $grey-80;
            margin-top: 20px;
          }
        }
        
        .sitemap {
          margin: 40px 0 48px;
          
          .title {
            font-weight: $bold-text;
            font-size: 16px;
            line-height: 24px;
            color: $black;
          }
          
          .sitemapLinks {
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: 24px 24px;
            margin-top: 24px;
            gap: 24px 32px;
            
            @media screen and (min-width: $large-content-width) {
              margin-top: 10px;
            }
            
            a {
              text-decoration: none;
              color: $grey-90;
              font-weight: 400;
            }
          }
          
          @media screen and (min-width: $large-content-width) {
            margin: 0;
            width: 30%;
            min-width: 280px;
          }
        }
      }
    }
      
      .bottom {
      padding: 14px 20px;
      width: calc(100vw - 40px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: $blue-dark;
      color: $white;
      line-height: 24px;
      
      .left {
        display: flex;
        align-items: flex-start;
      }

      .items {
        display: flex;
        gap: 38px;
        padding: 32px 0 0;
        
        @media screen and (min-width: $large-content-width) {
          padding: 0;
          width: 30%;
        }
      }

      .copyright {
        font-size: 14px;
      }

      .link {
        color: $white;
        font-size: 14px;
      }

      @media screen and (min-width: $large-content-width) {
        flex-direction: row;
        padding: 15px 10% 28px;
        width: calc(100vw - 20%);
      }
    }
  }
}