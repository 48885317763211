@import "src/styles/base.scss";

:local {
  .wrapper {
    width: 100%;
    height: 100vh;
    background-color: $white;
    position: relative;
    overflow-y: hidden;
  }

  .textContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 24px 20px 20px;
    justify-content: center;
    background-color: $white;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    z-index: 1000;
    
    @media screen and (min-width: $break-phablet-min) {
      width: 360px;
      padding: 20px;
    }
    
    img {
      width: 160px;
      padding: 10px 0;
      margin: 24px auto 80px;
    }

    .headline {
      text-align: center;
      font-size: $font-size-xxl;
      color: $grey-90;
      margin: 0;
      font-weight: bold;

      @media screen and (max-width: $break-phablet-min) {
        font-size: $font-size-xl;
      }
    }
  }
  .copyright {
    position: absolute;
    display: block;
    bottom: 5px;
    left: 5px;
    font-size: $font-size-xs;
    opacity: .5;

    &.dark {
      color: $white;
    }
  }
  .imageContainer {
    padding-top: 205px;
    padding-bottom: 10%;
    margin: 0 auto 0;
    width: 100%;
    height: 80%;
    
    @media screen and (min-width: $break-tablet-min) {
      width: 80%;
    }
    
    .image {
      object-fit: cover;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      @media screen and (min-width: $break-tablet-min) {
        height: 80%;
      }
    }
  }
}