@import '../../styles/base.scss';

:local {
  .wrapper {
    max-width: 280px;
  }
  
  .infoBox {
    position: absolute;
    transform: translate(-50%, -50%);
    margin-top: -140px;
    margin-left: 140px;
    text-align: center;
    max-width: 125px;
    padding: 1px;
    svg {
      transition: $transition-slow;
    }
  }

  .doner {
    font-size: 12px;
    line-height: 16px;
    font-weight: $bold-text;
  }

  .amount {
    font-size: 20px;
    line-height: 24px;
    margin-top: 9px;
  }

  .clickIcon {
    cursor: pointer;
    display: flex;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover {
      svg {
        width: 37px;
        height: 64px;
      }
    }
  }
}