@import '../../styles/base.scss';

:local {
  .developmentConsole {
    position:relative;
    max-width: 289px;
    overflow: hidden;
    min-height: 416px;
    padding: 18px 18px 0;
    background-color: $blue-light;
    border-radius: $border-radius-standard;
  }

  .contentSlider {
    display: flex;
    gap: 28px;
    align-items: stretch;
    transform: translateX(0);
    transition: $transition-slow;
  }
}