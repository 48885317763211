@import '../../../styles/base.scss';

:local {
  .nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: $blue-light;
    z-index:2000;
  }
  .year {
    width: 48px;
    text-align: center;
    font-size: 12px;
    line-height: 28px;
    color: #878786;
    cursor: pointer;
    transition: $transition-slow;
  }
  .active {
    background: $blue-dark;
    border-radius: $border-radius-standard;
    color: $white;
    cursor: default;
  }
}
