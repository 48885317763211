@import "../../styles/base.scss";
:local {
  .navbar {
    padding: 8px 6px;
    position: fixed;
    bottom: 30px;
    height: 40px;
    width: 324px;
    background-color: $white;
    border-radius: 0px;
    @include box-shadow;
    z-index: 100;
    border: 1px solid rgb(235, 235, 235);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    transition: $transition-slow;
    opacity: 1;
    visibility: inherit;
  }
  
  .navbarHidden {
    opacity: 0;
    visibility: hidden;
  }

  .divider {
    margin-left: 3px;
    width: 1px;
    height: 40px;
    background-color: $grey-10;
  }
  .label {
    @include box-shadow;
    width: fit-content;
    transform: translateY(-10px);
    width: 200px;
  }
  .labelWrapper {
    position: absolute;
    transform: translateY(-50px);
    background-color: $white;
    padding: 5px 8px;
    @include box-shadow;
    opacity: 0;
    border-radius: 4px;
    
    &.active {
      opacity: 1;
    }
    .smartCity {
      display: block;
    }
    .bildung {
      display: block;
    }
    .reallabor {
      display: block;
    }
    .neueTechnologien {
      display: block;
    }
    .kultur {
      display: block;
    }
  }
  .iconWrapper {
    height: 30px;
    width: 30px;
    cursor: pointer;
    padding: 5px;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    margin: 0 3.5px;

    color: $grey-90;

    svg {
      width: 30px;
      height: 30px;
      opacity: 0.2;
      transition: $transition-fast;

      &:hover {
        opacity: 0.6;
      }
    }
    &.active {
      svg {
        opacity: 1;
      }
    }
  }
}
