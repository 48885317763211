@import '../../styles/base.scss';

:local {
  .wrapper {
    height: auto;
    width: auto;
    max-width: $large-content-width;
    margin: 160px 0 0 auto;
    @include flex();
    
    @media (min-width: $medium-content-width) {
      margin-right: auto;
    }
  }
}