// colors
$black: #000000;

$grey-90: #3B3B3A; //ex $dark-grey--1
$grey-80: #565656; //ex $dark-grey--2
$grey-70: #6F6F6E; //ex $dark-grey--3
$grey-60: #878786; //ex $dark-grey--4
$grey-50: #9C9D9D; //ex $dark-grey--5
$grey-40: #B1B2B3; //ex $dark-grey--6
$grey-30: #C5C6C6; //ex $dark-grey--7
$grey-20: #DADADA; //ex $dark-grey--8
$grey-15: #E3E3E3;
$grey-10: #ECECED;
$grey-5: #F6F6F6;

$white: #ffffff;

$blue-dark: #1E398F; //ex $blue--2
$blue-light: #E2F4FD;
$corporate-blue: #385BA7;
$corporate-blue-transparent: #385ba720;

$font-sans: 'IBM Plex Sans';
$font-mono: 'IBM Plex Mono';

// layout
$medium-content-width: remify(560px) !default;
$large-content-width: remify(840px) !default;

// spacesw
$space-extra-small: 2.5px;
$space-small: 5px;
$space-regular: 10px;
$space-large: 20px;
$space-extra-large: 50px;

$line-height: 150%;
$bold-text: 700;

// letter-spacing
$letter-spacing-xl: 0.125em !default; // 2px;
$letter-spacing-l: 0.08em !default; // 1.28px;
$letter-spacing-m: 0.03em !default; // 0.8px;
$letter-spacing-s: 0.03em !default; // a half of a pixel;

// Standard breakpoints (in 'em' for purpose)
$break-phablet-min: remify(520px) !default;
$break-tablet-min: remify(768px) !default;
$break-desktop-min: remify(980px) !default;
$break-desktop-max: remify(1360px) !default;
$break-desktop-extra-max: remify(1600px) !default;
$break-areas: $break-tablet-min !default;
$break-page-padding: $break-tablet-min !default;

$border-radius-extra-small: 3px;
$border-radius-small: 5px;
$border-radius-medium: 10px;
$border-radius-large: 20px;
$border-radius-extra-large: 40px;
$border-radius-standard: 2px;

// font-sizes
$font-size-xs: 0.563rem;
$font-size-s: 0.75rem;
$font-size-m: 1rem;
$font-size-l: 1.333rem;
$font-size-xl: 1.777rem;
$font-size-xxl: 2.369rem;
$font-size-xxxl: 3.157rem;
$font-size-xxxxl: 4.209rem;

$transition-fast: .125s all ease-in-out;
$transition-slow: .25s all ease-in-out;

$section-margin: 160px auto 60px;