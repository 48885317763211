@import '../../../styles/base.scss';

:local {

  .timestamp {
    display: flex;
    margin-bottom: 16px;
    margin-left: -5px;
  }

  .dot {
    min-width: 8px;
    height: 8px;
    background-color: $black;
    border-radius: 50%;
    margin-top: 15px;
  }
  
  .connector {
    height: 1px;
    width: 54px;
    background-color: $black;
    margin-top: 18px;
  }

  .infoBox {
    padding: 24px 24px 25px;
    max-width: 408px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }

  .month {
    font-weight: $bold-text;
    font-size: 36px;
    line-height: 23px;
    color: $blue-dark;
    margin: 0 0 32px;
  }

  @media screen and (min-width: $large-content-width) {
    .timestamp {
      width: 408px;
      margin-top: 164px;
    }

    .timestampLeft {
      flex-direction: row-reverse;
      margin-left: -405px;
      margin-top: 0;
      position: absolute;
    }

  }
}
